import {
  createApi,
  BaseQueryFn,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../dynamicBaseQuery";
import {
  ActiveMatchesCom,
  ActiveUserReq,
  ActiveUserRes,
  AllParentLedgerReq,
  AllParentLedgerRes,
  CasinoLedgerPayload,
  CasinoLedgerResponse,
  CasinoPlusMinus,
  CasinoPlusMinusProps,
  CasinoPlusMinusRes,
  ChanelRes,
  ChangePaaReq,
  ChangePaaRes,
  ChannelDetail,
  ClientLimitReq,
  ClientLimitRes,
  ClientPlusMinusReq,
  ClientPlusMinusRes,
  CompletedLedgerRes,
  DataReportReq,
  DataReportRes,
  DeletedBetById,
  DeletedBetByIdByTime,
  EventDataRes,
  FancyBookReq,
  FancyBookRes,
  LedgerBody,
  LedgerCasinoListRes,
  LedgerCasinoListResponse,
  LedgerDetailsReq,
  LedgerDetailsRes,
  LedgerPaylod,
  LedgerPostCasinoReq,
  LedgerPostReq,
  LedgerPostRes,
  LimitReq,
  LogOutRes,
  LoginReportReq,
  LoginReportRes,
  MatchBetForDeleted,
  MatchBetsRes,
  MatchSessionReq,
  MatchSessionRes,
  OddsPnlReq,
  OddsResponse,
  PostledgerRes,
  SessionBetForDeleted,
  SessionBetResForDeleted,
  SessionBetsRes,
  SessionHavingRes,
  SessionPlusMinusRes,
  UpdateChannelId,
  UpdateUserRes,
  UserCreateBody,
  UserCreateRequestBody,
  UserCreateResBody,
  UserDetailsUpdateReq,
  UserDetailsUpdateRes,
  UserProfile,
  UserRequestBody,
  UserResponse,
  activeEvent,
  activeEventData,
  casinoResponse,
  casioBetListReq,
  casioBetListRes,
  channelReq,
  clientRequest,
  clientResponse,
  completdLedgerReqest,
  completedProps,
  completedReq,
  eventLockReq,
  matchedBetResForDeleted,
  myLedgerReq,
  myLedgerRes,
  postledgerReq,
  updateLimitReq,
  updateLimitRes,
  useNameRequest,
  useNameRes,
} from "./user";

export const userList = createApi({
  reducerPath: "userList",
  baseQuery: dynamicBaseQuery as BaseQueryFn<
    string | { url: string; method: string; body?: any },
    unknown,
    FetchBaseQueryError
  >,
  endpoints: (build) => ({
    userList: build.mutation<UserResponse, UserRequestBody>({
      query: (body) => ({
        url: "/user/list-user",
        method: "POST",
        body,
      }),
    }),
    userName: build.mutation<useNameRes, useNameRequest>({
      query: (body) => ({
        url: "/user/username-id-search",
        method: "POST",
        body,
      }),
    }),
    userDetailForEdit: build.mutation<useNameRes, UserCreateRequestBody>({
      query: (body) => ({
        url: "/user/get-detail-for-user-creation",
        method: "POST",
        body,
      }),
    }),
    userCreate: build.mutation<UserCreateResBody, UserCreateBody>({
      query: (body) => ({
        url: "/user/create",
        method: "POST",
        body,
      }),
    }),
    userProfile: build.mutation<UserProfile, void>({
      query: () => ({
        url: "/user/get-self-profile",
        method: "POST",
      }),
    }),
    ChangePassword: build.mutation<ChangePaaRes, ChangePaaReq>({
      query: (body) => ({
        url: "/user/changepassword-self",
        method: "POST",
        body,
      }),
    }),
    userDetailEdit: build.mutation<UserDetailsUpdateRes, UserDetailsUpdateReq>({
      query: (body) => ({
        url: "/user/user-detail-for-edit",
        method: "POST",
        body,
      }),
    }),
    userActive: build.mutation<ActiveUserRes, ActiveUserReq>({
      query: (body) => ({
        url: "/user/activate-deactivate-user",
        method: "POST",
        body,
      }),
    }),
    LedgerDepositWidthdraw: build.mutation<LedgerBody, LedgerPaylod>({
      query: (body) => ({
        url: "/ledger/ledger-dep-wid",
        method: "POST",
        body,
      }),
    }),
    LedgerDetails: build.mutation<LedgerDetailsRes, LedgerDetailsReq>({
      query: (body) => ({
        url: "/ledger/get-ledger-cash-trans-userid",
        method: "POST",
        body,
      }),
    }),
    getClientDetails: build.mutation<clientResponse, clientRequest>({
      query: (body) => ({
        url: "/report/client-having-active-bets-odds-session",
        method: "POST",
        body,
      }),
    }),
    getMatchandSessionBet: build.mutation<MatchSessionRes, MatchSessionReq>({
      query: (body) => ({
        url: "/report/get-match-session-bets",
        method: "POST",
        body,
      }),
    }),
    getClientActiveBet: build.mutation<clientResponse, clientRequest>({
      query: (body) => ({
        url: "/report/client-having-active-bets-odds",
        method: "POST",
        body,
      }),
    }),
    getClientFancyBet: build.mutation<clientResponse, clientRequest>({
      query: (body) => ({
        url: "/report/client-having-active-bets-fancy",
        method: "POST",
        body,
      }),
    }),
    getSessionHavingBetBet: build.mutation<SessionHavingRes, clientRequest>({
      query: (body) => ({
        url: "/report/session-having-bets",
        method: "POST",
        body,
      }),
    }),
    getMatchBets: build.mutation<MatchBetsRes, MatchSessionReq>({
      query: (body) => ({
        url: "/report/get-match-bets",
        method: "POST",
        body,
      }),
    }),
    getSessionBet: build.mutation<SessionBetsRes, MatchSessionReq>({
      query: (body) => ({
        url: "/report/get-session-bets",
        method: "POST",
        body,
      }),
    }),
    LogOut: build.mutation<LogOutRes, void>({
      query: (body) => ({
        url: "/login/logout",
        method: "POST",
        body,
      }),
    }),
    getSessionPlusMinus: build.mutation<SessionPlusMinusRes, clientRequest>({
      query: (body) => ({
        url: "/report/get-session-plus-minus",
        method: "POST",
        body,
      }),
    }),
    getCompletedEvent: build.mutation<completedProps, completedReq>({
      query: (body) => ({
        url: "/sports/get-completed-events",
        method: "POST",
        body,
      }),
    }),
    getFancyBook: build.mutation<FancyBookRes, FancyBookReq>({
      query: (body) => ({
        url: "/enduser/fancy-book",
        method: "POST",
        body,
      }),
    }),
    getClientListForLimit: build.mutation<ClientLimitRes, ClientLimitReq>({
      query: (body) => ({
        url: "/user/child-list-for-limit",
        method: "POST",
        body,
      }),
    }),
    getUpdateLimit: build.mutation<updateLimitRes, updateLimitReq>({
      query: (body) => ({
        url: "/user/update-limit",
        method: "POST",
        body,
      }),
    }),
    getCompletedLedger: build.mutation<CompletedLedgerRes, completdLedgerReqest>({
      query: (body) => ({
        url: "/report/get-complete-ledger",
        method: "POST",
        body,
      }),
    }),
    getDataReport: build.mutation<DataReportRes, DataReportReq>({
      query: (body) => ({
        url: "/user-activity-report/get-user-activity",
        method: "POST",
        body,
      }),
    }),
    getLoginReport: build.mutation<LoginReportRes, LoginReportReq>({
      query: (body) => ({
        url: "/login-report/user-login-report",
        method: "POST",
        body,
      }),
    }),
    getOddsPnl: build.mutation<OddsResponse, OddsPnlReq>({
      query: (body) => ({
        url: "/report/odds-pnl",
        method: "POST",
        body,
      }),
    }),
    getPnl: build.mutation<OddsResponse, OddsPnlReq>({
      query: (body) => ({
        url: "/user/child-list-for-limit",
        method: "POST",
        body,
      }),
    }),
    getLimit: build.mutation<OddsResponse, LimitReq>({
      query: (body) => ({
        url: "/user/child-list-for-limit",
        method: "POST",
        body,
      }),
    }),
    getUpdateUser: build.mutation<updateLimitRes, UpdateUserRes>({
      query: (body) => ({
        url: "/user/update-detail",
        method: "POST",
        body,
      }),
    }),
    getMyLedger: build.mutation<myLedgerRes, myLedgerReq>({
      query: (body) => ({
        url: "/ledger/my-ledger",
        method: "POST",
        body,
      }),
    }),
    getClientPlusMinus: build.mutation<ClientPlusMinusRes, ClientPlusMinusReq>({
      query: (body) => ({
        url: "/ledger/get-client-plus-minus",
        method: "POST",
        body,
      }),
    }),
    getAllParentLedger: build.mutation<AllParentLedgerRes, AllParentLedgerReq>({
      query: (body) => ({
        url: "/ledger/get-ledger-all-parent",
        method: "POST",
        body,
      }),
    }),
    getCasinoBetListQuery: build.query<casioBetListRes, casioBetListReq>({
      query: (body) => ({
        url: "/casino/casino-bet-list-admin",
        method: "POST",
        body,
      }),
    }),
    getCasinoBetList: build.mutation<casioBetListRes, casioBetListReq>({
      query: (body) => ({
        url: "/casino/casino-bet-list-admin",
        method: "POST",
        body,
      }),
    }),
    getCasinoList: build.mutation<casinoResponse, void>({
      query: (body) => ({
        url: "/casino/casino-list",
        method: "POST",
        body,
      }),
    }),
    getCasinoPlusMinus: build.mutation<casinoResponse, CasinoPlusMinus>({
      query: (body) => ({
        url: "/casino/get-casino-player-table-list",
        method: "POST",
        body,
      }),
    }),
    getCasinoLedger: build.mutation<CasinoLedgerResponse, CasinoLedgerPayload>({
      query: (body) => ({
        url: "/casino/get-casino-ledger",
        method: "POST",
        body,
      }),
    }),
    CasinoPlusMinus: build.mutation<CasinoPlusMinusRes, CasinoPlusMinusProps>({
      query: (body) => ({
        url: "/casino/get-casino-detail-daywise-plusminus",
        method: "POST",
        body,
      }),
    }),
    matchListPostledger: build.mutation<PostledgerRes, postledgerReq>({
      query: (body) => ({
        url: "/sports/match-list-postledger-androllback",
        method: "POST",
        body,
      }),
    }),
    postLedger: build.mutation<PostledgerRes, LedgerPostReq>({
      query: (body) => ({
        url: "/ledger/post-ledger",
        method: "POST",
        body,
      }),
    }),
    postLedgerCasino: build.mutation<LedgerPostRes, LedgerPostCasinoReq>({
      query: (body) => ({
        url: "/casino/post-ledger",
        method: "POST",
        body,
      }),
    }),
    casinoListLedger: build.mutation<LedgerCasinoListResponse, LedgerCasinoListRes>({
      query: (body) => ({
        url: "/casino/casino-list-ledger-rollback",
        method: "POST",
        body,
      }),
    }),
    casinoRollBack: build.mutation<LedgerPostRes, LedgerPostCasinoReq>({
      query: (body) => ({
        url: "/casino/rollback-ledger",
        method: "POST",
        body,
      }),
    }),
    ledgerRollBack: build.mutation<LedgerPostRes, LedgerPostReq>({
      query: (body) => ({
        url: "/ledger/rollback-ledger",
        method: "POST",
        body,
      }),
    }),
    getChanelId: build.mutation<ChanelRes, channelReq>({
      query: (body) => ({
        url: `/sports/channel-id-matchidwise`,
        method: "POST",
        body
      }),
    }),
    getActiveEventList: build.mutation<EventDataRes, void>({
      query: (body) => ({
        url: `/sports/active-event-list`,
        method: "POST",
        body
      }),
    }),
    getEventLock: build.mutation<LedgerPostRes, eventLockReq>({
      query: (body) => ({
        url: `/sports/upadte-event-activation`,
        method: "POST",
        body
      }),
    }),
    getEventListForChannelid: build.mutation<EventDataRes, void>({
      query: (body) => ({
        url: `/sports/event-list-score-channelid-update`,
        method: "POST",
        body
      }),
    }),
    getChannelDetail: build.mutation<ChannelDetail, void>({
      query: (body) => ({
        url: `/token-free/get-channel-detail-tp`,
        method: "GET",
        body
      }),
    }),
    getUpdateChannel: build.mutation<LedgerPostRes, UpdateChannelId>({
      query: (body) => ({
        url: `/sports/update-channel-scoreid`,
        method: "POST",
        body
      }),
    }),
    getCompletedMatchesActive: build.mutation<ActiveMatchesCom, void>({
      query: (body) => ({
        url: `/report/get-active-bets-of-completed-matches`,
        method: "POST",
        body
      }),
    }),
    getEventLedgerNotPost: build.mutation<activeEventData, void>({
      query: (body) => ({
        url: `/sports/event-ledger-not-posted`,
        method: "POST",
        body
      }),
    }),
    getSessionBetDeleted: build.mutation<SessionBetForDeleted, SessionBetResForDeleted>({
      query: (body) => ({
        url: `/report/get-session-bets-to-delete`,
        method: "POST",
        body
      }),
    }),
    getMatchedBetDeleted: build.mutation<MatchBetForDeleted, matchedBetResForDeleted>({
      query: (body) => ({
        url: `/report/get-match-bets-to-delete`,
        method: "POST",
        body
      }),
    }),
    getDeletdBet: build.mutation<LedgerPostRes, DeletedBetById>({
      query: (body) => ({
        url: `/superadmin/delete-bet-byid`,
        method: "POST",
        body
      }),
    }),
    getDeletdBetByTime: build.mutation<LedgerPostRes, DeletedBetByIdByTime>({
      query: (body) => ({
        url: `/superadmin/delete-bet-bytime`,
        method: "POST",
        body
      }),
    }),
  }),
});

export const {
  useUserListMutation,
  useUserNameMutation,
  useUserDetailForEditMutation,
  useUserCreateMutation,
  useUserProfileMutation,
  useChangePasswordMutation,
  useUserDetailEditMutation,
  useUserActiveMutation,
  useLedgerDepositWidthdrawMutation,
  useLedgerDetailsMutation,
  useGetClientDetailsMutation,
  useGetMatchandSessionBetMutation,
  useGetClientActiveBetMutation,
  useGetMatchBetsMutation,
  useGetClientFancyBetMutation,
  useGetSessionHavingBetBetMutation,
  useGetSessionBetMutation,
  useLogOutMutation,
  useGetSessionPlusMinusMutation,
  useGetCompletedEventMutation,
  useGetFancyBookMutation,
  useGetClientListForLimitMutation,
  useGetUpdateLimitMutation,
  useGetCompletedLedgerMutation,
  useGetDataReportMutation,
  useGetLoginReportMutation,
  useGetOddsPnlMutation,
  useGetLimitMutation,
  useGetUpdateUserMutation,
  useGetMyLedgerMutation,
  useGetClientPlusMinusMutation,
  useGetAllParentLedgerMutation,
  useGetCasinoBetListMutation,
  useGetCasinoListMutation,
  useGetCasinoPlusMinusMutation,
  useGetCasinoLedgerMutation,
  useCasinoPlusMinusMutation,
  useGetCasinoBetListQueryQuery,
  useMatchListPostledgerMutation,
  usePostLedgerMutation,
  usePostLedgerCasinoMutation,
  useCasinoListLedgerMutation,
  useCasinoRollBackMutation,
  useLedgerRollBackMutation,
  useGetChanelIdMutation,
  useGetActiveEventListMutation,
  useGetEventLockMutation,
  useGetEventListForChannelidMutation,
  useGetChannelDetailMutation,
  useGetUpdateChannelMutation,
  useGetCompletedMatchesActiveMutation,
  useGetEventLedgerNotPostMutation,
  useGetSessionBetDeletedMutation,
  useGetDeletdBetMutation,
  useGetMatchedBetDeletedMutation,
  useGetDeletdBetByTimeMutation
} = userList;
